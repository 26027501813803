import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};



export default function Logo({ disabledLink = false, sx }) {
    const theme = useTheme();

    const logo = (<Box sx = {{ width: 60, height: 40, ...sx } } >
       <img width ="100%" height="100%" src = '/logo/logo.png' alt = "logo"/>
       
       </Box>);

    if (disabledLink) {return  <> { logo } </>;}

    return <RouterLink to = "/home"> { logo } </RouterLink>;
    }