import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

const initialState = {
  isLoading: false,
  error: null,
  notifications: [],
};
const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setNotifications(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
  },
});

export default slice.reducer;
export const { setNotifications } = slice.actions;

export function getNotifications() {
    console.log("load #............");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log("load notifications............");
      const response = await axios.get("/api/log/sim-status");
      dispatch(slice.actions.setNotifications(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
