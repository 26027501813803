import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Container, Stack, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../hooks/useOffSetTop';
import useResponsive from '../hooks/useResponsive';
// utils
import cssStyles from '../utils/cssStyles';
// config
import { HEADER } from '../config';
// components
import Logo from '../components/Logo';
//

import SettingPopover from './SettingPopover';
import LanguagePopover from './LanguagePopover';
// hook
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  // boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();
  const { user } = useAuth();
  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/home';
  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container>

          <Stack direction="row" justifyContent="space-between" alignItems={"center"} >
            <Logo />
            <Typography>
              {user?.username}
            </Typography>
            <Stack justifyContent={"space-between"} alignItems={"center"} direction="row" gap={1}>
              <LanguagePopover />
              <SettingPopover />
            </Stack>

          </Stack>

        </Container>
      </ToolbarStyle>
    </AppBar>
  );
}

