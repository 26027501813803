
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import {useTranslation} from 'react-i18next';
// @mui
import { Stack, Button, Dialog, Card, IconButton, Typography, TextField, Divider, Alert, } from '@mui/material';

// axios
import axios from '../utils/axios';
import Iconify from '../components/Iconify';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const pinCode = true;

PinCodeModal.propTypes = {
  onModalClose: PropTypes.func,
  phoneNumber: PropTypes.string,
  username:PropTypes.string,
}
export default function PinCodeModal({ onModalClose, username, phoneNumber, ...other }) {
  const { enqueueSnackbar } = useSnackbar();
  const [mismatch, setMismatch] = useState(false);
  const nameRef = useRef('');
  const oldRef = useRef('');
  const newRef = useRef('');
  const comRef = useRef('');
  const { initialize } = useAuth();
  const {t} = useTranslation();
  const handleOldPin = (event) => {
    oldRef.current = event.target.value;
  };
  const handleNewPin = (event) => {
    newRef.current = event.target.value;
  };
  const handleComfirm = (event) => {
    comRef.current = event.target.value;
  }
  const handleName = (event) => {
    nameRef.current = event.target.value;
  };
  const handleSubmit = async () => {

    try {
      const username = nameRef.current;
      const oldPinCode = oldRef.current;
      const newPinCode = newRef.current;
      const comPinCode = comRef.current;
      if (newPinCode !== comPinCode) {
        setMismatch(true);
      } else {
        const result = await axios.post('/api/auth/set-pincode', { phoneNumber, username, oldPinCode, newPinCode });

        if (result.data.success) {
          initialize();
          enqueueSnackbar(result.data.message, { variant: 'success' });
          onModalClose();
        }
        else {
          enqueueSnackbar(result.data.message, { variant: 'error' });
        }
      }

    }
    catch (err) {
   //   console.log(err);
    }

  };

  return (
    <Dialog
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      scroll={'body'}
      maxWidth={'xs'}
      onClose={onModalClose}
      {...other}

    >
      <Card sx={{ bgcolor: 'primary.dark', p: 3 }}>
        <Stack spacing={2} direction={'row'} alignItems={'center'} justifyContent={'center'} color={'text.secondary'}>
          <Iconify icon={'ic:round-security'} width={24} height={24} />
          <Typography variant={'h4'} >
            {pinCode ? `${t('words.change_code')}` : `${t('words.create_code')}`}
          </Typography>
        </Stack>
        <Typography sx={{ textAlign: 'center', mb: 2 }} variant={'subtitle1'} color={'text.secondary'} >
          {t('pinModal.title')}
        </Typography>
        <IconButton
          sx={{ position: 'absolute', right: 10, top: 10, zIndex: 1 }}
          onClick={onModalClose}
        >
          <Iconify icon={'eva:close-fill'} width={30} height={30} />
        </IconButton>
        <Divider sx={{ mb: 3 }} />
        <Stack spacing={2} justifyContent={'center'}>
          <TextField
            label={`${t('words.nickname')}`}
            defaultValue={username}
            onChange={handleName}
          />
          {pinCode && <TextField
            type="password"
            label={`${t('words.old_pin')}`}
            onChange={handleOldPin}
          />}
          <TextField
            type="password"
            label={`${t('words.new_pin')}`}
            onChange={handleNewPin}
          />
          <TextField
            type="password"
            label={`${t('words.confirm_pin')}`}
            onChange={handleComfirm}
          />
          {mismatch && <Alert severity="error"> {t('pinModal.mismatch_error')}</Alert>}{' '}

          <Button
            variant={'contained'}
            fullWidth
            onClick={handleSubmit}
          >
           {t('words.save_change')}
          </Button>
        </Stack>
      </Card >

    </Dialog>
  );
}





