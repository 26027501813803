import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

CarFront.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  color:PropTypes.string,
};



export default function CarFront({ disabledLink = false, sx,color }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = color!==undefined?color:theme.palette.grey[500_48] ;
  // theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const svg = (
    <Box sx={{ width: 'inherit', height: 'inherit', ...sx }}>


      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100%" height="100%" viewBox="0 0 220.000000 180.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,229.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none">
          <path d="M714 1820 c-29 -4 -58 -11 -65 -16 -43 -25 -89 -69 -158 -150 l-78
-91 -11 30 -11 30 -72 -6 c-149 -13 -160 -82 -18 -121 32 -10 59 -19 59 -21 0
-2 -20 -13 -44 -25 -55 -26 -121 -96 -149 -158 -20 -43 -22 -66 -25 -272 -4
-253 -1 -282 34 -317 17 -17 24 -35 24 -64 0 -29 7 -47 25 -64 21 -22 33 -25
93 -25 86 0 111 16 119 78 l6 42 658 0 659 0 0 -25 c0 -33 25 -81 45 -89 9 -3
47 -6 84 -6 83 0 111 22 111 87 0 32 7 48 30 73 l31 33 -3 256 c-3 244 -4 258
-26 303 -30 60 -89 121 -147 151 l-46 23 58 18 c77 24 103 41 103 70 0 28 -27
43 -101 54 -66 10 -99 1 -99 -28 0 -11 -3 -20 -8 -20 -4 0 -44 42 -88 93 -100
115 -148 149 -223 158 -74 10 -702 9 -767 -1z m787 -60 c40 -11 127 -97 213
-209 l50 -64 -49 6 c-211 29 -962 34 -1174 7 -46 -6 -86 -8 -89 -5 -12 12 180
235 222 257 12 6 59 15 106 19 120 11 677 3 721 -11z m-147 -321 c28 -22 96
-136 96 -161 0 -9 -7 -19 -16 -22 -9 -3 -161 -6 -339 -6 -378 0 -367 -3 -319
87 16 30 43 71 60 89 l31 34 230 0 c217 0 232 -1 257 -21z m-952 -208 c84 -23
159 -48 176 -61 32 -24 47 -59 32 -74 -4 -4 -90 -7 -189 -4 -216 5 -221 7
-221 99 0 45 4 60 18 68 24 14 21 15 184 -28z m1596 9 c17 -34 8 -98 -18 -124
-19 -20 -33 -21 -205 -24 -171 -4 -185 -3 -192 14 -5 13 4 27 35 54 36 29 65
41 185 72 78 20 151 36 162 35 11 -1 25 -13 33 -27z m-1352 -288 c13 -8 84
-146 84 -162 0 -11 -129 -14 -146 -2 -17 12 -103 156 -98 164 6 10 145 10 160
0z m834 -9 c0 -10 -17 -49 -38 -88 l-37 -70 -295 -2 c-162 -2 -300 0 -306 5
-13 8 -84 146 -84 162 0 7 127 10 380 10 355 0 380 -1 380 -17z m240 7 c0 -13
-89 -153 -104 -162 -16 -11 -134 -10 -141 2 -6 10 48 124 73 153 12 13 31 17
94 17 45 0 78 -4 78 -10z"/>
        </g>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return <>{svg}</>;
  }

  return <RouterLink to="/">{svg}</RouterLink>;
}
