import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
} 

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
export function fRemain(current, period) {
  const rm = period - current;
  const d = Math.floor(rm / 3600 / 24 / 1000);
  const h = Math.floor((rm - d * 3600 * 24 * 1000) / 3600 / 1000);
  const m = Math.floor((rm - d * 3600 * 24 * 1000 - h * 3600 * 1000) / 60 / 1000);
  const value = ((d > 0 ? (`${d}d `) : '') + (h > 0 ? (`${h}h `) : '') + (m > 0 ? (`${m}m `) : ''));
  return {
      text:`${value}`,
      isRemain:rm>0,

  }
  
}
export function fRemainD(remain) {
  const rm = remain;
  const d = Math.floor(rm / 3600 / 24 / 1000);
  const h = Math.floor((rm - d * 3600 * 24 * 1000) / 3600 / 1000);
  const m = Math.floor((rm - d * 3600 * 24 * 1000 - h * 3600 * 1000) / 60 / 1000);
  const value = ((d > 0 ? (`${d}d `) : '') + (h > 0 ? (`${h}h `) : '') + (m > 0 ? (`${m}m `) : ''));
  return {
      text:`${value}`,
      isRemain:rm>0,

  }
  
}