import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
    try{
        return format(new Date(date), 'dd MMMM yyyy');
    }
    catch(err){
        return ''
    }
    
}
export function formatSQLDate(date) {
    if (date)
        return format(new Date(date), 'yyyy-MM-dd');
    else
        return ""
}
export function fDateTime(date) {
    try{
        return format(new Date(date), 'dd MMM yyyy HH:mm');
    }
    
    catch(err){
        return ''
    }
}

export function fTimestamp(date) {
    return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}

export function fTimer(date) {
    if (date)
        return format(new Date(date), 'hh:mm:ss');
    else
        return ''
}