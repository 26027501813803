export const HOST_API = "https://www.aslaa.mn/"
//  export const HOST_API = "http://localhost:5001/"
export const HEADER = {
    MOBILE_HEIGHT: 50,
    MAIN_DESKTOP_HEIGHT: 70,
}

export const FIREBASE_API = {
    apiKey: "AIzaSyDA_x4YElOAVmT4rS3B-xcmCzhvefDTOrI",
    authDomain: "rccdemo-41279.firebaseapp.com",
    projectId: "rccdemo-41279",
    storageBucket: "rccdemo-41279.appspot.com",
    messagingSenderId: "963013875719",
    appId: "1:963013875719:web:f9511f343bceb59b06f2a2"
}
export const PUSHER_API = {
    apiKey: "92d8bd6d3400b94c11ca",
    cluster: "ap1",
}

export const PRICE_PER_MONTH = 5000;
export const OrderPrice = 2000;