import {

  Dialog,
  Divider,

  Grid,

  Stack,

  Typography,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axios from "../../../utils/axios";

import { FormProvider, RHFTextField } from "../../../components/hook-form";

const verificationStatus = ["no-license", "pending", "verified"];

export default function SendNotificationDialog({ open, onClose, users }) {
  const { t } = useTranslation();
  const defaultValues = {
    title: "",
    body: "",
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    const result = await axios.post("/api/admin/notification/send", { users, ...data });
    console.log(result.data.success, result.data.sent);
    if(result?.data?.sent > 0){
        
        enqueueSnackbar(`Sent to ${result?.data.sent}  users`);
    }
    else{
        enqueueSnackbar(`Notification didn't send any users`,{variant:'error'});
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xl"}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ padding: 2, paddingX: 4 }} minWidth={340}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {t("notification.title")}
                </Typography>
                <RHFTextField name={"title"} />
              </Stack>
              <Stack>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  {t("notification.body")}
                </Typography>
                <RHFTextField multiline rows={6} name={"body"} />
              </Stack>
              <Divider sx={{ m: 2 }} />
              <Stack spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  sx={{
                    bgcolor: "grey.50016",
                    border: "1px solid",
                    borderColor: "grey.50048",
                  }}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Send Notification
                </LoadingButton>{" "}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </FormProvider>
    </Dialog>
  );
}
