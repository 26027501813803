import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { MenuItem, IconButton } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  onDisable: PropTypes.func,
  onEnable:PropTypes.func,
  onLicense:PropTypes.func,
  onDriver:PropTypes.func,
  deviceId: PropTypes.string,
};

export default function UserMoreMenu({ onDisable, onEnable, onLicense, onDriver}) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />{' '}
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 170,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem onClick={onDisable} sx={{ color: 'grey.500' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
          Delete{' '}
        </MenuItem>
        <MenuItem onClick={onEnable}  >
          <Iconify icon={'akar-icons:eye'} sx={{ ...ICON }} />
          View Devices{' '}
        </MenuItem>{' '}
        <MenuItem onClick={onLicense}  >
          <Iconify icon={'mdi:license'} sx={{ ...ICON }} />
          License
        </MenuItem>{' '}
        <MenuItem onClick={onDriver}  >
          <Iconify icon={'mdi:drivers-license-outline'} sx={{ ...ICON }} />
          Driver Info
        </MenuItem>{' '}
      </MenuPopover>{' '}
    </>
  );
}
