import { Button, Chip, Dialog, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next';
import axios from '../../../utils/axios';

import { HOST_API, PRICE_PER_MONTH } from '../../../config';
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';

const verificationStatus = ['no-license', 'pending', 'verified'];

export default function DriverLicenseDialog({ open, onClose, user }) {
    const image = user?.driverLicenseFile || '';
    const { t } = useTranslation();
  
    const { enqueueSnackbar } = useSnackbar();
 
    const handleVerification = async (verification) => {
        const response = await axios.post(`/api/admin/user/driver-license-verification`, { user: user?._id, verification });
        if (response.status === 200) {
            enqueueSnackbar('Verified successfully');
            onClose();
        }
        else {
            enqueueSnackbar('Could not changed', { variant: 'error' })
        }
    }
 
    return (
        <Dialog open={open} onClose={onClose}>
            <Stack sx={{ padding: 2, paddingX:4 }}>
                <Grid container spacing={3}  >

                    <Grid item xs={12} >
                        <Stack direction="row" justifyContent={'space-between'}>
                            <Typography variant='h4' sx={{ mt: 2 }}>

                                {t("driver.information")}

                            </Typography>
                            <Chip sx={{ ml: 2 }} label={verificationStatus[user?.driverLicenseVerification || 0]}
                                size="small" color='success' />
                        </Stack>

                        <Divider sx={{ mb: 4, mt: 1 }} />

                        <Stack spacing={3}>
                            <TextField label={`${t("driver.name")}`} disabled value={user?.username} />
                            <TextField label={`${t("driver.address")}`} disabled value={user?.address} />
                            <TextField label={`${t("driver.description")}`} disabled value={user?.description}/>
                            {image != '' ?
                                <img src={`${HOST_API}${image}`} style={{ width: '100%' }} />
                                :
                                <img src={`/images/driver-license.png`} style={{ width: '100%', }} />
                            }
                            <Stack direction='row' gap={1}>
                                <Button fullWidth size="large" sx={{ bgcolor: 'grey.50016', border: '1px solid', borderColor: 'grey.50048' }}
                                    onClick={()=>handleVerification(1)} variant="contained"  >
                                    Pending
                                </Button>
                                <Button fullWidth size="large" sx={{ bgcolor: 'grey.50016', border: '1px solid', borderColor: 'grey.50048' }}
                                    onClick={()=>handleVerification(2)} variant="contained"  >
                                    Verify
                                </Button>
                            </Stack>
                            
                        </Stack>


                    </Grid>
                </Grid>
            </Stack>


        </Dialog>
    )
}