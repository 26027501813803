import { useLocation, Outlet } from "react-router-dom";
// @mui
import { Stack } from "@mui/material";
// components
import Logo from "../components/Logo";
//
import MainHeader from "./MainHeader";
import useAuth from "../hooks/useAuth";
import { useEffect } from "react";
import axios from "../utils/axios";
import { getNotifications } from "../redux/slices/notification";
import { dispatch } from "../redux/store";

// ----------------------------------------------------------------------

export default function Layout() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  useEffect(() => {
    if (user && user.device) {
      console.log("check online");
      // check online
      axios
        .post(`/api/device/checkline`, {
          deviceNumber: user?.device?.deviceNumber,
        })
        .then((res) => {
          console.log("check simcard");
          // check simcard
         
        })
        .catch((err) => {});
        
      // dispatch(getNotifications());
    }
  }, [user]);
  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />
      <Outlet />
    </Stack>
  );
}
