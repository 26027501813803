import { Link as RouterLink } from 'react-router-dom';
// @mui

import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography, Divider } from '@mui/material';

import Image from '../../components/Image';
// routes
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';

// hooks 
// components
import Page from '../../components/Page';

import Logo from '../../components/Logo';
// sections 
import LoginForm from '../../sections/auth/LoginForm';
import CarFront from '../../components/CarFront';
import LogoOnlyLayout from '../../layout/LogoOnlyLayout';
import axios from '../../utils/axios';
import { HOST_API } from 'src/config';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------  


// const HeaderStyle = styled('header')(({ theme }) => ({
//   top: 0,
//   zIndex: 9,
//   lineHeight: 0,
//   width: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   position: 'absolute',
//   padding: theme.spacing(3),
//   justifyContent: 'space-between',
//   [theme.breakpoints.up('md')]: {
//     padding: theme.spacing(2, 5, 0, 7),
//   },
// }));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  // padding: theme.spacing(8, 0),
  alignContent: 'space-between',
  gap: 3
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">


      <ContentStyle>
        <Typography variant="h3" gutterBottom textAlign={'center'}>
          Машины хяналтын систем
        </Typography>

        <Box width={"50%"} sx={{ mx: 'auto', mb: 3, mt: -3 }}>
          <CarFront />
        </Box>
        <Typography paragraph textAlign={'center'}>
          Утасны дугаараа оруулна уу
        </Typography>
        <Box width={'80%'} mx={'auto'} my={3}>
          <LoginForm />
        </Box>
        
      </ContentStyle>

    </Page>
  );
}
