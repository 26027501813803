import { Button, Chip, Dialog, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next';
import axios from '../../../utils/axios';

import { PRICE_PER_MONTH } from '../../../config';
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';

export default function LicenseDialog({ open, onClose, user }) {
    
    const [totalPrice, setTotalPrice] = useState(PRICE_PER_MONTH);
    const [days, setDays] = useState(30);
    const { t } = useTranslation();
    const [expired, setExpired] = useState(new Date());
    const { enqueueSnackbar } = useSnackbar();
    
    const handleChange = (e) => {
        
        setDays(parseInt(e.target.value, 10));
        const date = new Date(user?.expired || new Date());
        date.setDate(date.getDate() + parseInt(e.target.value, 10)*30);
        console.log(date, " is changed value");
        setExpired((date));
        setTotalPrice((e.target.value * PRICE_PER_MONTH));
    }
    const handleLicenseKey = async () => {
        const response = await axios.post(`/api/admin/user/extend-license`, { user: user?._id, licenseKey:user?.licenseKey, expired });
        if (response.status === 200) {
            enqueueSnackbar('Extended successfully');
            onClose();
        }
        else{
            enqueueSnackbar('',{variant:'error'})
        }
    }
    useEffect(()=>{
        if(user?.expired)
        setExpired(new Date(Date.parse(user?.expired)))
    },[user])
    return (
        <Dialog open={open} onClose={onClose}>
            <Stack  sx = {{padding:2}}>
                <Grid container spacing={3}  >

                    <Grid item xs={12} >
                        <Typography variant='h4' sx={{ mt: 2 }}>
                            {user?.username || user?.phoneNumber}&nbsp;
                            {t("device_profile.license_information")}
                            <Chip sx={{ ml: 2 }} label={user?.status}
                                size="small" />
                        </Typography>
                        <Divider sx={{ mb: 4, mt: 1 }} />

                        <Stack spacing={3}>

                            <TextField label={`${t("words.license")}`} disabled value={user?.licenseKey} />
                            <TextField label={`${t("words.expired")}`} disabled value={fDate(expired)} />
                            <FormControl>
                                <InputLabel id="period-select-label">{t("words.period")}</InputLabel>
                                <Select label="Period" onChange={handleChange} value={`${days}`} labelId="period-select-label">
                                    <MenuItem value='1' >1 Month</MenuItem>
                                    <MenuItem value='2'>2 Months</MenuItem>
                                    <MenuItem value='3'>3 Months</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography sx={{ textAlign: 'right' }}>{t("device_profile.total_price")}: {fShortenNumber(totalPrice)}</Typography>
                            <Button fullWidth size="large" sx={{ bgcolor: 'grey.50016', border: '1px solid', borderColor: 'grey.50048' }}
                                onClick={handleLicenseKey} variant="contained"  >
                                {t("device_profile.increase_license")}
                            </Button>
                        </Stack>


                    </Grid>
                </Grid>
            </Stack>


        </Dialog>
    )
}