import { useState, useRef } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Link, useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, codeVerify } = useAuth();
  const navigate = useNavigate();
  const [pinInput, setPinInput] = useState(false);
  const [failed, setFailed] = useState(false);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.number().min(99999, 'phone number can not least than 6 digits').max(1000000000, 'phone number can not greater than 10 digits').required('Phone Number is required'),
  });

  const defaultValues = {
    phoneNumber: '',
    pinCode: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onLogin = async (data) => {
    try {
      const result = await login(`${data.phoneNumber}`);

      if (result === 'pincode') {
        setPinInput(true);
      } else if (result === 'otp') {
        navigate('/auth/verify');
      } else if (result === 'navigate') {

        navigate('/home');
      }
      else if (result === 'inactive') {
        setError('afterSubmit', 'Your account is disabled, please contact with admin');
      }
    } catch (error) {
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', 'verification server error');
      }
    }
  };

  const onPinCode = async (data) => {
    try {
      const result = await codeVerify(data.phoneNumber, data.pinCode);
      if (result.success) {
        enqueueSnackbar(result.message, { variant: 'success' });
        navigate('/home');
      } else {
        setFailed(true)
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(!pinInput ? onLogin : onPinCode)}>
        <Stack spacing={3}>
          {' '}
          {!!errors.afterSubmit && <Alert severity="error"> {errors.afterSubmit.message} </Alert>}{' '}
          <RHFTextField name="phoneNumber" label="утасны дугаар" />
          {pinInput && <RHFTextField name="pinCode" label="pinCode" />}{' '}
          {failed &&
            <Stack direction="row" justifyContent={'end'} >

              <Typography variant='subtitle2' sx={{ color: 'grey.500', cursor: 'pointer' }} onClick={() => navigate('/auth/forgot-password', { replace: true })}>Forgot Password?</Typography>

            </Stack>
          }
          <LoadingButton
            fullWidth
            size="large"
            sx={{ bgcolor: 'grey.50016', border: '1px solid', borderColor: 'grey.50048' }}
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {' '}
            {pinInput ? 'confirm' : 'login'}{' '}
          </LoadingButton>{' '}
        </Stack>{' '}
      </FormProvider>{' '}
      <div id="recaptcha-container" />
    </>
  );
}
