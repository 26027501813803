import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import Layout from '../layout';
import LogoOnlyLayout from '../layout/LogoOnlyLayout';
// components

import LoadingScreen from '../components/LoadingScreen';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import DeviceManage from 'src/pages/admin/DeviceManage';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'verify', element:(<GuestGuard> <VerifyCode /></GuestGuard>) },
        { path: 'forgot-password', element:(<GuestGuard> <ForgotPassword /></GuestGuard>) },
      ],
    },
    {
      path: '',
      
      children: [
        // { element:(<AuthGuard>  <Home /> </AuthGuard>), index:true},
        { element:(<LandingPage />), index:true},
        { path: 'home', element:(<AuthGuard>  <Home /> </AuthGuard>)},
        { path: 'car-rent', element:(<AuthGuard>  <CarRent /> </AuthGuard>)},
        { path: 'help', element:(<AuthGuard>  <HelpPage /> </AuthGuard>)},
        { path: 'Rentscan', element:(<AuthGuard>  <RentScan /> </AuthGuard>)},
        { path: 'device-register', element:(<AuthGuard>  <DeviceRegister /> </AuthGuard>)},
        { path: 'license-profile', element:(<AuthGuard>  <LicenseProfile /> </AuthGuard>)},
        { path: 'pin-code', element:(<AuthGuard>  <PinCode /> </AuthGuard>)},
        { path: 'time-command', element:(<AuthGuard>  <TimeCommand /> </AuthGuard>)},
        { path: 'log-management', element:(<AuthGuard>  <LogManagement /> </AuthGuard>)},
        { path: 'log-map', element:(<AuthGuard>  <CarLocationsWithRouter /> </AuthGuard>)},
        { path: 'log-sim', element:(<AuthGuard>  <SimCardLogs /> </AuthGuard>)},
        { path: 'log-gps', element:(<AuthGuard>  <GpsHistoryOnMap /> </AuthGuard>)},
        { path: 'configure-driver', element:(<AuthGuard>  <ConfigureDrivers /> </AuthGuard>)},
        { path: 'configure-gps/:id', element:(<AuthGuard>  <ConfigGPS /> </AuthGuard>)},
        { path: 'log-license', element:(<AuthGuard>  <LogLicense /> </AuthGuard>)},
        { path: 'Order', element:(<AuthGuard>  <Order /> </AuthGuard>)},
        { path: 'driver-profile', element:(<AuthGuard>  <DriverProfile /> </AuthGuard>)},
        { path: 'notification', element:(<AuthGuard>  <Notification /> </AuthGuard>)},

      ],
    },
    {
      path: 'admin',
      children: [
        { path: 'device/:id', element: <AuthGuard> <DeviceEdit /></AuthGuard> },
        { path: 'device-dashboard', element: <AuthGuard><DeviceDashboard /></AuthGuard> },
        { path: 'user-manage', element: <AuthGuard><UserManage /></AuthGuard> },
        { path: 'device-manage', element: <AuthGuard><DeviceManage /></AuthGuard> },
        { path: 'orders', element: <AuthGuard> <Orders /></AuthGuard> },
        { path: 'transactions', element: <AuthGuard> <TransactionLogs /></AuthGuard> },
        { path: 'withdraw-request', element: <AuthGuard> <WithdawRequests /></AuthGuard> },
        { path: 'rent-car-status', element: <AuthGuard> <RentCarLogs /></AuthGuard> },
        { path: 'orders', element: <AuthGuard> <Orders /></AuthGuard> },

      ],
    },


    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));

const Home = Loadable(lazy(() => import('../pages/Home')));
const LandingPage = Loadable(lazy(() => import('../pages/Landing')));
const RentScan = Loadable(lazy(() => import('../pages/RentScan')));
const PinCode = Loadable(lazy(()=>import('../pages/PinCode')));
const TimeCommand = Loadable(lazy(()=>import('../pages/TimeCommand')));

const HelpPage = Loadable(lazy(()=>import('../pages/helpPage')));

const LogManagement = Loadable(lazy(()=>import('../pages/LogManagement')));
const LogLicense = Loadable(lazy(()=>import('../pages/InvoiceLog')));
const Notification = Loadable(lazy(()=>import('../pages/Notification')));

const CarLocationsWithRouter = Loadable(lazy(()=>import('../pages/CarLocationsWithRouter')));
const SimCardLogs = Loadable(lazy(()=>import('../pages/SimCardLog')));

const GpsHistoryOnMap = Loadable(lazy(()=>import('../pages/GpsHistoryOnMap')));
const CarRent = Loadable(lazy(()=>import('../pages/CarRent')));
const ConfigureDrivers = Loadable(lazy(() => import('../pages/ConfigureDrivers')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const LicenseProfile = Loadable(lazy(() => import('../pages/LicenseProfile')));
const DriverProfile = Loadable(lazy(() => import('../pages/DriverProfile')));

const DeviceRegister = Loadable(lazy(() => import('../pages/DeviceRegister')));
const ConfigGPS = Loadable(lazy(() => import('../pages/ConfigGPS')));
const Order = Loadable(lazy(() => import('../pages/order')));
const Profile = Loadable(lazy(() => import('../pages/profile')));
// admin 
const DeviceDashboard = Loadable(lazy(() => import('../pages/admin/DeviceDashboard')));
const DeviceEdit = Loadable(lazy(()=>import('../pages/admin/DeviceEdit')));
const UserManage = Loadable(lazy(() => import('../pages/admin/UserManage')));
const TransactionLogs = Loadable(lazy(() => import('../pages/admin/TransactionLogs')));
const WithdawRequests = Loadable(lazy(() => import('../pages/admin/WithdrawLogs')));
const RentCarLogs = Loadable(lazy(() => import('../pages/admin/RentCarList')));
const Orders = Loadable(lazy(() => import('../pages/admin/OrderList')));
