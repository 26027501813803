import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState, useEffect, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';

// @mui
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    Table,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Divider,
    Dialog,
    DialogTitle,
    Stack,
    DialogContent,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItem,
    IconButton,
    Box,
    DialogActions,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from '../../components/hook-form';

// sections
import { DeviceListHead, DeviceListToolbar, DeviceMoreMenu } from '../../sections/admin/device';
import axios from "../../utils/axios";
import Layout from '../../layout';
import { LoadingButton } from '@mui/lab';

export default function DeviceDialog({ user, open, onClose }) {
    const [current, setCurrent] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [devices, setDevices] = useState([]);
    const EditSchema = Yup.object().shape({

    });
    const defaultValues = useMemo(() => ({
        deviceNumber: current?.deviceNumber || '',
        type: current?.type || '4g',
        uix: current?.uix || 'Car',
        isDefault: current?.isDefault || true,
    }), [current]);

    const methods = useForm({
        resolver: yupResolver(EditSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;
    const onSubmit = (data) => {
        axios.post('/api/device/register', { ...data,_id:current?._id, phoneNumber: user?.phoneNumber }).then(res => {
            if (res.status === 200 && res.data.success) {
                enqueueSnackbar(res.data?.message || 'Saved successful', { variant: 'success' })
                reset();
                load();
            }
            else {
                enqueueSnackbar(res.data?.message, { variant: 'error' })
            }
        }).catch(err => {

        }).finally(() => {

        })
    }
    const handleDelete = (device) => {
      
        axios.delete(`/api/device/delete/${device._id}`).then(res => {
            load();
        })
    }
    useEffect(() => {

        reset(defaultValues);
    }, [current])
    const load = () => {

        axios.post('/api/device/gets', { phoneNumber: user.phoneNumber }).then(res => {
            if (res.status === 200 && res.data.success) {
                setDevices(res.data.devices);
            }
        }).catch(err => { }).finally(() => {

        })
    }
    useEffect(() => {
        if (user)
            load();
    }, [user])
    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>

            <DialogTitle >

                {`${user?.username || user?.phoneNumber}`}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Stack gap={1}>
                    <List>
                        <Divider />
                        {devices?.map((device, index) => (
                            <Box key={index}>
                                <ListItem >
                                    <ListItemIcon>
                                        <Iconify height={30} width={30} icon={`${device?.uix === 'Car' ? 'fa-solid:car' : 'ion:hardware-chip-outline'}`}></Iconify>
                                    </ListItemIcon>
                                    <ListItemText >
                                        {device?.deviceNumber}
                                    </ListItemText>
                                    <ListItemIcon>


                                        <Iconify height={30} width={30} icon={`${device?.type === '4g' ? 'healthicons:network-4g-outline' : 'arcticons:sms-gate'}`}></Iconify>
                                    </ListItemIcon>
                                    <IconButton onClick={() => { handleDelete(device) }}>
                                        <Iconify height={20} width={20} icon={'fluent:delete-16-regular'}></Iconify>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        setCurrent(device);
                                    }}>
                                        <Iconify height={20} width={20} icon={'arcticons:set-edit'}></Iconify>
                                    </IconButton>
                                </ListItem>
                                <Divider />
                            </Box>
                        ))}
                    </List>
                </Stack>
                <Stack gap={2}>
                    <Typography sx={{ mt: 2 }}>Device Information</Typography>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3}>

                            <RHFTextField name="deviceNumber" label="Device Number" />
                            <RHFSelect name="type" label="Device Type">
                                <option value="4g">4G Net</option>
                                <option value="sms">SMS</option>
                            </RHFSelect>
                            <RHFSelect name="uix" label="UIX Type">
                                <option value="Car">Car</option>
                                <option value="Chip">Chip</option>
                            </RHFSelect>
                            <RHFSwitch name="isDefault" label="Default Device">

                            </RHFSwitch>
                            <Stack gap={1} direction={{ sm: 'row', xs: 'column' }}>
                                <Button fullWidth size="large" sx={{ bgcolor: 'grey.50016', border: '1px solid', borderColor: 'grey.50048' }} onClick={() => { setCurrent(null) }}>Reset</Button>
                                <LoadingButton fullWidth size="large" sx={{ bgcolor: 'grey.50016', border: '1px solid', borderColor: 'grey.50048' }}
                                    type="submit" variant="contained" loading={isSubmitting}>
                                    Save Changes.
                                </LoadingButton>
                            </Stack>
                        </Stack>

                    </FormProvider>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}