import PropTypes from "prop-types";

// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  Box,
  Stack,
} from "@mui/material";
// components
import Iconify from "../../../components/Iconify";
import InputStyle from "../../../components/InputStyle";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 70,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDisableDevice: PropTypes.func,
  onEnableDevice: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDisableDevice,
  onSendNotification,
}) {
  const theme = useTheme();

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "text.primary",
          bgcolor: "primary.dark",
        }),
      }}
    >
      {" "}
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {" "}
          {numSelected}
          selected{" "}
        </Typography>
      ) : (
        <InputStyle
          size="small"
          stretchStart={240}
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          placeholder="Search user..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />{" "}
              </InputAdornment>
            ),
          }}
        />
      )}
      {numSelected > 0 && (
        <Stack direction={'row'} gap = {1}>
          <IconButton onClick={onSendNotification}>
            <Iconify icon={"mdi:bell-notification-outline"} />{" "}
          </IconButton>{" "}
          <IconButton onClick={onDisableDevice}>
            <Iconify icon={"eva:trash-2-outline"} />{" "}
          </IconButton>{" "}
        </Stack>
      )}
    </RootStyle>
  );
}
