import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Chip, Button } from '@mui/material';
import { useTranslation } from "react-i18next";
// routes 
// hooks
import useAuth from '../hooks/useAuth';
import useIsMountedRef from '../hooks/useIsMountedRef';
// components
import MenuPopover from '../components/MenuPopover';
import { IconButtonAnimate } from '../components/animate';
import Iconify from '../components/Iconify';
import { mn } from 'date-fns/locale';
//


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Монгол',
        value: 'mn',
        icon: 'twemoji:flag-mongolia'
    },
    {
        label: 'English',
        value: 'en',
        icon: 'twemoji:flag-england'
    },
    {
        label: 'Россия',
        value: 'ru',
        icon: 'twemoji:flag-russia'
    },
];

// ----------------------------------------------------------------------

export default function SettingPopover() {
    const navigate = useNavigate();
    const [menuOptions, setMenuOptions] = useState(MENU_OPTIONS);
    const [currentLng, setCurrentLng] = useState(MENU_OPTIONS[0]);

    const { t, i18n, ready } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(null);
    const [pinModal, setPinModal] = useState(false);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);

    };

    const handleChangeLng = (option) => {
        localStorage.setItem("language", option.value);
        i18n.changeLanguage(option.value);
        setCurrentLng(option);
        setOpen(null)
    }

    const handlePinModalClose = () => {
        setPinModal(false);
    }

    useEffect(() => {
        const lang = localStorage.getItem("language");
        //  || "mn";
        // setCurrentLng(lang === "mn" && MENU_OPTIONS[0] || (lang === "en" && MENU_OPTIONS[1] || MENU_OPTIONS[2]));
        if(!lang || lang === 'mn'){
            handleChangeLng(menuOptions[0]);
        }
        else if(lang === 'en'){
            handleChangeLng(menuOptions[1]);

        }
        else if(lang === 'ru'){
            handleChangeLng(menuOptions[2]);

        }
    }, []);


    return (
        <>
            <IconButtonAnimate onClick={handleOpen}
                sx={
                    {
                        p: 0,
                        ...(open && {
                            '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.1),
                            },
                        }),
                    }
                } >
                <Iconify icon={currentLng.icon}
                    width={
                        { sx: 20, md: 30 }}
                    height={
                        { sx: 20, md: 30 }}
                /> </IconButtonAnimate>


            <MenuPopover open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={
                    {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                            lineHeight: 1,
                        },
                    }
                } >

                <Stack sx={{ p: 1 }} >
                    {
                        menuOptions.map((option) => (
                            <MenuItem key={option.label}
                                to={option.linkTo}
                                component={Button}

                                onClick={() => handleChangeLng(option)} sx={{ minHeight: { xs: 24 } }}>
                                <Iconify icon={option.icon} width={24} height={24} />
                                &nbsp;&nbsp;{option.label}

                            </MenuItem>
                        ))

                    }
                </Stack>

            </MenuPopover>

        </>
    );
}