import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

// @mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Divider,
  Skeleton,
} from "@mui/material";
// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";

// sections
import axios from "../../utils/axios";
import Layout from "../../layout";
import TableListHead from "../../sections/admin/user/TableListHead";
import UserMoreMenu from "../../sections/admin/user/UserMoreMenu";
import UserListToolbar from "../../sections/admin/user/UserListToolbar";
import DeviceDialog from "./DeviceDialog";
import LicenseDialog from "../../sections/admin/user/LicenseDialog";
import DriverLicenseDialog from "../../sections/admin/user/DriverLicenseDialog";
import { fShortenNumber } from "../../utils/formatNumber";
import SendNotificationDialog from "src/sections/admin/user/SendNotificationDilaog";

const verificationStatus = ["no-license", "pending", "verified"];

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "PhoneNumber", label: "Phone Number" },
  { id: "DeviceNumber", label: "Device Number" },
  { id: "Type", label: "Type" },
  { id: "Created at", label: "Created at" },
  { id: "uix", label: "uix" },
  { id: "isDefault", label: "isDefault" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function UserManage() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [current, setCurrent] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDevice, setOpenDevice] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openLicense, setOpenLicense] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n._id); //  n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteDevice = async (_id, status) => {
    const list = devices.slice().filter((device) => device._id !== _id);
    const res = await axios.delete(`/api/device/delete/${_id}`, {
      data: { status }, // Send the status in the request body if needed
    });
  
    if (res.status === 200 && res.data.success) {
      setDevices(list);
      enqueueSnackbar("Selected user is deleted from the database.");
    }
  };
  const handleDisableMultiDevice = async (status) => {
    const list = devices
      .slice()
      .filter((device) => !selected.includes(device._id));
    const res = await axios.post("/api/admin/user/delete", {
      ids: selected,
      status,
    });
    if (res.status === 200 && res.data.success) {
      setDevices(list);
      enqueueSnackbar("Selected users are deleted from the database.");
    }
  };
  const handleSendNotifcation = () => {
    if (selected.length > 0) {
      setOpenNotification(true);
    }
  };
  const handleDevice = (row) => {
    setCurrent(row);
    setOpenDevice(true);
  };
  const handleClose = () => {
    setOpenDevice(false);
    load();
  };
  const handleLicense = (row) => {
    setCurrent(row);

    setOpenLicense(true);
  };
  const handleDriver = (row) => {
    setCurrent(row);

    setOpenDriver(true);
  };
  const load = async () => {
    try {
      setLoading(true);
      const response = await axios.post("/api/device/list/all");
      setDevices(response.data.list);
    } catch (error) {
      console.error("Error fetching devices:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    load();
  }, []);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  const filteredUsers = applySortFilter(
    devices,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers?.length && Boolean(filterName);

  return (
    <Page title="User Manage">
      <Container sx={{ py: { xs: 12 } }}>
        <Layout />
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDisableDevice={() => handleDisableMultiDevice("inactive")}
            onSendNotification={() => handleSendNotifcation()}
          />
          <Divider />
          <Scrollbar sx={{ padding: 2 }}>
            {loading &&
              [1, 2, 3, 4, 5].map((index) => (
                <Skeleton height={30} key={index} animation="pulse" />
              ))}
            {!loading && (
              <TableContainer>
                <Table size="small" stickyHeader>
                  <TableListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={devices?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                <TableBody>
  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
    const {
      phoneNumber,
      deviceNumber,
      type,
      createdAt,
      uix,
      isDefault // Ensure isDefault is correctly accessed from the data
    } = row;

    return (
      <TableRow
        hover
        key={row._id}
        tabIndex={-1}
        role="checkbox"
        selected={selected.indexOf(row._id) !== -1}
        aria-checked={selected.indexOf(row._id) !== -1}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected.indexOf(row._id) !== -1}
            onClick={() => handleClick(row._id)}
          />
        </TableCell>
        <TableCell align="left">{phoneNumber}</TableCell>
        <TableCell align="left">{deviceNumber}</TableCell>
        <TableCell align="left">{type}</TableCell>
        <TableCell align="left">{createdAt}</TableCell>
        <TableCell align="left">{uix}</TableCell>
        <TableCell align="left">
  {typeof isDefault !== 'undefined' ? String(isDefault) : 'N/A'}
</TableCell>
        <TableCell align="right">
          <UserMoreMenu
            onDisable={() => handleDeleteDevice(row._id)}
            onEnable={() => handleDevice(row)}
            onLicense={() => handleLicense(row)}
            onDriver={() => handleDriver(row)}
          />
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filteredUsers?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <DeviceDialog open={openDevice} device={current} onClose={handleClose} />
      <DriverLicenseDialog
        open={openDriver}
        device={current}
        onClose={() => {
          setOpenDriver(false);
          load();
        }}
      />
      <LicenseDialog
        open={openLicense}
        device={current}
        onClose={() => setOpenLicense(false)}
      />
      <SendNotificationDialog
        devices={selected}
        open={openNotification}
        onClose={() => setOpenNotification(false)}
      />
    </Page>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    const partialDeviceSearch = array.filter((user) => {
      const lastFiveDigits = user?.deviceNumber?.slice(-5);
      return lastFiveDigits && lastFiveDigits.includes(query);
    });

    const phoneNumberSearch = array.filter((user) => {
      return user?.phoneNumber.includes(query);
    });

    const result = [...partialDeviceSearch, ...phoneNumberSearch];
    const uniqueResult = Array.from(new Set(result.map((user) => user._id))).map((id) => {
      return result.find((user) => user._id === id);
    });

    return uniqueResult;
  }

  return stabilizedThis?.map((el) => el[0]);
}
