import PropTypes from 'prop-types';

// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, InputAdornment, Box } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 70,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

DeviceListToolbar.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
    onFilterPaid: PropTypes.func,
    onDisableDevice: PropTypes.func,
    onEnableDevice: PropTypes.func,
};

export default function DeviceListToolbar({ filterName, onFilterName }) {

    return (<RootStyle >
        <InputStyle size="small"
            stretchStart={240}
            value={filterName}
            onChange={
                (event) => onFilterName(event.target.value)}
            placeholder="Search ..."
            InputProps={
                {
                    startAdornment: (<            InputAdornment position="start" >
                        <            Iconify icon={'eva:search-fill'}
                            sx={
                                { color: 'text.disabled', width: 20, height: 20 }}
                        />{' '} </InputAdornment>
                    ),
                }
            }
        /> </RootStyle>
    );
}